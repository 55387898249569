@import url('../src/res/styles/font-poppins.css');
@import url('../src/res/styles/font-leaner.css');

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: potra;
	font-style: normal;
	src: url(./res/fonts/Potra.ttf);
}

body {
  margin: 0;
  padding: 0;
  background-color: black;
  color: whitesmoke;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
